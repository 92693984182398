import getSymbolFromCurrency from 'currency-symbol-map'
import VueApexCharts from 'vue-apexcharts'
import getEnv from '@/util/env'
import { ExportForm } from '@/classes/ExportForm'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import { Colors } from '@/classes/colors/Colors'
import { ImpactCost } from '@/classes/stats/ImpactCost.js'
import BrowserCache from '@/services/browser-cache.js'
import StatsService from '@/services/stats.service'
import GeneralStatsService from '@/services/general-stats.service'
import DateRange from '@/components/DateRange/DateRange.vue'
import ImpactCostGeneral from '@/components/Graphs/ImpactCostGeneral/ImpactCostGeneral.vue'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import DeliveredMailing from '@/components/Stats/Mailing/DeliveredMailing.vue'
import UserInteractionMailing from '@/components/Stats/Mailing/UserInteractionMailing.vue'
import UnsubscribedMailing from '@/components/Stats/Mailing/UnsubscribedMailing.vue'
import EventBus from '@/util/EventBus'
import StatsMixin from '../../Mixins/StatsMixin'
import { MailingCalculatedData } from '@/classes/stats/Calculations/Mailing/MailingCalculatedData'
import { MailingGraphicData } from '@/classes/stats/MailingGraphicData'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import Pie from '@/components/Stats/Pie/Pie.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'ChannelMailingStats',
  components: {
    HeaderTopDashboard,
    StatsTitle,
    DateRange,
    apexchart: VueApexCharts,
    ImpactCostGeneral,
    DeliveredMailing,
    UserInteractionMailing,
    UnsubscribedMailing,
    StatCard,
    Pie,
    RadialBar,
    RadialBarWithLegend,
    SimpleBar,
    StackedMultiple,
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      debouncedInput: '',
      timeout: null,
      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalCampaigns: 0,
      campaignIds: null,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      additional: undefined,
      data: [],
      deliveredChart: {
        options: {},
      },
      ratioOpenChart: {
        options: {},
      },
      ratioClickThroughChart: {
        options: {},
      },
      ratioClickOpenChart: {
        options: {},
      },
      ratioBouncedChart: {
        options: {},
      },
      devicesChart: {
        options: {},
      },
      dataDevices: {
        totalOpened: 0,
        isMobile: 0,
      },
      unsubscribed: {
        percentage: '0',
        total: 0,
        deliveredTotal: 0,
        unsubscribedUnique: 0,
      },
      startDate: '',
      endDate: '',
      perPage: '5',
      exportForm: new ExportForm(),
      ready: true,
      impactCost: new ImpactCost(),
      series: this.initSeries(),
      barChartOptions: {
        title: {
          text: this.$t('Interaciones de usuario'),
        },

        dataLabels: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: [Colors.colorChartSent, Colors.colorBarDelivered, Colors.colorBarOpened, Colors.colorBarClicked,
        Colors.colorBarSoftBounced, Colors.colorBarHardBounced, Colors.colorBarUnsubscribed],
        xaxis: {
          categories: [],
        },
      },
      campaginDataSource: {},
      readyStats: false,
      mailingCalculatedData: new MailingCalculatedData(),
      clicksRatioGraphicData: null,
      clickToOpenRatioGraphicData: null,
      cards: [],
      openedRatioGraphicData: null,
      deliveredGraphicData: null,
      undeliveredGraphicData: null,
      unsubscribedRatioBarChart: null,
      reBouncedRatioGraphicData: null,
      openedDevicesGraphicData: null,
      interactionItems: [],
      openedDevicesStats: null,
      loadedTableFirstTime: false,

    }
  },
  methods: {
    downloadUndeliveredCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const params = {
        campaignIds: this.campaignIds,
        customFields: false,
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
        sendingEvents: [5, 8],
        columns: ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'subject', 'from', 'reply', 'event', 'countryIso', 'createdAt', 'externalId', 'Browser', 'OS', 'IP', 'URL'],
        token: user.token,
        hasInverseEvents: false,
      }
      const url = getEnv('backend_endpoint') + 'api/stats/mailing/channel-bounced-export-csv?' + new URLSearchParams(params).toString()
      window.location.href = url
    },

    setGraphicsData () {
      const mailingGraphicData = new MailingGraphicData(this.mailingCalculatedData, this)
      this.cards = mailingGraphicData.getCardsData()
      this.clicksRatioGraphicData = mailingGraphicData.getChannelClicksRatioGraphicData()
      this.clickToOpenRatioGraphicData = mailingGraphicData.getChannelClickToOpenRatioGraphicData()

      this.deliveredRatioGraphic = mailingGraphicData.getDeliveredRatioGraphicGraphicData()
      this.openedRatioGraphicData = mailingGraphicData.getOpenedRatioGraphicData()
      this.deliveredGraphicData = mailingGraphicData.getDeliveredGraphicData()
      this.undeliveredGraphicData = mailingGraphicData.getUndeliveredGraphicData()
      this.unsubscribedRatioBarChart = mailingGraphicData.getUnsubscribedRatioBarChartGraphicData()
      this.reBouncedRatioGraphicData = mailingGraphicData.getReBouncedRatioGraphicData()
      this.openedDevicesGraphicData = mailingGraphicData.getOpenedDevicesGraphicData(this.openedDevicesStats)
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(5)
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      params = this.getParams()
      StatsService.getMailingChannelDatatable(params)
        .then(
          (response) => {
            this.readyStats = false
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalCampaigns = response.total
            if (this.totalCampaigns > 0) {
              this.setPieChart(this.additional[0])
            }
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
      this.getimpactCostStats()
    },
    initSeries () {
      return [
        {
          name: this.$t('Enviados'),
          data: [],
        },
        {
          name: this.$t('Entregados'),
          data: [],
        },
        {
          name: this.$t('Abiertos'),
          data: [],
        },
        {
          name: this.$t('Clics'),
          data: [],
        },
        {
          name: 'Soft Bounces',
          data: [],
        },
        {
          name: 'Hard Bounces',
          data: [],
        },
        {
          name: this.$t('Bajas'),
          data: [],
        },
      ]
    },
    setTableData (data) {
      this.campaignIds = []
      const series = this.initSeries()
        data.forEach(data => {
          this.campaignIds.push(data.campaign_id)
          series[0].data.push(data.sent_total)
          series[1].data.push(data.delivered_total)
          series[2].data.push(data.opened_unique)
          series[3].data.push(data.clicked_unique)
          series[4].data.push(data.soft_bounced_unique)
          series[5].data.push(data.hard_bounced_unique)
          series[6].data.push(data.unsubscribed_total)
        })
      this.content = data
      this.series = series
      this.loadDeviceChart()
    },

    loadDeviceChart () {
      StatsService.getDeviceChartByCampaignIds({
        campaignIds: this.campaignIds,
      })
        .then(
          (response) => {
            this.openedDevicesStats = response

            setTimeout(() => {
              this.mailingCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.readyStats = true
            })
          },
          () => { },
        )
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.hard_bounced_unique) + parseInt(data.hard_bounced_unique)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculateUnopened (data) {
      return parseInt(data.delivered_total) - parseInt(data.opened_unique)
    },

    // eslint-disable-next-line max-lines-per-function, complexity
    setPieChart (totals) {
      if (totals === undefined) {
        return
      }

      // delivered chart
      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.unopened = this.calculateUnopened(totals)

      this.deliveredChart.percentage = totals.delivered_total === 0 ? totals.delivered_total : ((totals.delivered_total / totals.sent_total) * 100).toFixed(2)
      this.deliveredChart.series = [totals.delivered_total, this.calculateUndeliveredTotal(totals)]
      this.deliveredChart.dataInfo = [
        {
          label: this.$t('Enviados').toString(),
          value: totals.sent_total,
        },
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('No entregados').toString(),
          value: this.calculateUndeliveredTotal(totals),
        },
      ]
      this.deliveredChart.options = {
        legend: {
          show: false,
        },
        fill: {
          type: 'solid',
          colors: [Colors.colorChartDelivered, Colors.colorChartUndelivered],
        },
        labels: [this.$t('Entregados').toString(), this.$t('No Entregados').toString()],
        colors: [Colors.colorChartDelivered, Colors.colorChartUndelivered],
      }

      // Ratio opened chart
      this.ratioOpenChart.percentage = totals.opened_unique === 0 ? totals.opened_unique : ((totals.opened_unique / totals.delivered_total) * 100).toFixed(2)
      this.ratioOpenChart.series = [parseInt(totals.opened_unique), this.calculateUnopened(totals)]
      this.ratioOpenChart.dataInfo = [
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('Abiertos').toString(),
          value: totals.opened_unique,
        },
        {
          label: this.$t('No abiertos').toString(),
          value: this.calculateUnopened(totals),
        },
      ]
      this.ratioOpenChart.options = {
        legend: {
          show: false,
        },
        fill: {
          type: 'solid',
          colors: [Colors.colorChartOpened, Colors.colorChartUnopened],
        },
        labels: [this.$t('Abiertos').toString(), this.$t('No abiertos').toString()],
        colors: [Colors.colorChartOpened, Colors.colorChartUnopened],
      }

      // Ratio click through chart
      this.ratioClickThroughChart.percentage = totals.delivered_total === 0 ? totals.delivered_total : ((totals.clicked_unique / totals.delivered_total) * 100).toFixed(2)
      this.ratioClickThroughChart.series = [parseInt(totals.delivered_total), parseInt(totals.clicked_unique)]
      this.ratioClickThroughChart.dataInfo = [
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('Clics únicos').toString(),
          value: totals.clicked_unique,
        },
      ]
      this.ratioClickThroughChart.options = {
        legend: {
          show: false,
        },
        fill: {
          type: 'solid',
          colors: [Colors.colorBarDelivered, Colors.colorChartUniqueClicks],
        },
        labels: [this.$t('Entregados').toString(), this.$t('Clics únicos').toString()],
        colors: [Colors.colorBarDelivered, Colors.colorChartUniqueClicks],
      }

      // Ratio click open chart
      this.ratioClickOpenChart.percentage = totals.opened_unique === 0 ? totals.opened_unique : ((totals.clicked_unique / totals.opened_unique) * 100).toFixed(2)
      this.ratioClickOpenChart.series = [parseInt(totals.opened_unique), parseInt(totals.clicked_unique)]
      this.ratioClickOpenChart.dataInfo = [
        {
          label: this.$t('Abiertos').toString(),
          value: totals.opened_unique,
        },
        {
          label: this.$t('Clics únicos').toString(),
          value: totals.clicked_unique,
        },
        {
          label: this.$t('Abiertos únicos').toString(),
          value: totals.opened_unique,
        },
      ]
      this.ratioClickOpenChart.options = {
        legend: {
          show: false,
        },
        fill: {
          type: 'solid',
          colors: [Colors.colorChartTotalClicks, Colors.colorChartUniqueClicks],
        },
        labels: [this.$t('Abiertos únicos').toString(), this.$t('Clics únicos').toString()],
        colors: [Colors.colorChartTotalClicks, Colors.colorChartUniqueClicks],
      }

      totals.hard_bounced_unique = parseInt(totals.hard_bounced_unique)
      totals.soft_bounced_unique = parseInt(totals.soft_bounced_unique)

      const isEmpty = totals.hard_bounced_unique + totals.soft_bounced_unique === 0
      const series = isEmpty ? [1] : [totals.hard_bounced_unique, totals.soft_bounced_unique]
      const colors = isEmpty ? ['#f1f1f1'] : [Colors.colorChartHardBounced, Colors.colorChartSoftBounced]
      const enabledTooltip = !isEmpty
      const enabledDataLabels = !isEmpty

      // Ratio bounced chart
      // eslint-disable-next-line no-nested-ternary
      this.ratioBouncedChart.percentage = totals.sent_total ? (totals.hard_bounced_unique ? ((totals.hard_bounced_unique / totals.sent_total) * 100).toFixed(2) : 0) : 0
      this.ratioBouncedChart.series = series
      this.ratioBouncedChart.dataInfo = [
        {
          label: this.$t('Enviados').toString(),
          value: totals.sent_total,
        },
        {
          label: this.$t('No Entregados').toString(),
          value: this.calculateUndeliveredTotal(totals),
        },
        {
          label: 'Hard Bounced',
          value: totals.hard_bounced_unique,
        },
        {
          label: 'Soft Bounced',
          value: totals.soft_bounced_unique,
        },
      ]
      this.ratioBouncedChart.options = {
        dataLabels: {
          enabled: enabledDataLabels,
        },
        tooltip: {
          enabled: enabledTooltip,
        },
        legend: {
          show: false,
        },
        fill: {
          type: 'solid',
          colors: colors,
        },
        labels: ['Hard Bounced', 'Soft Bounced'],
        colors: colors,
      }

      // Unsubscribed chart
      const unsubscribedPercentage = (totals.unsubscribed_total / totals.delivered_total) * 100
      this.unsubscribed.percentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(2) : '0'
      this.unsubscribed.total = parseInt(totals.unsubscribed_total)
    },

    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      this.setExportData()
      const url = getEnv('backend_endpoint') + 'api/stats/mailing/channel-export-' + urlSuffix + '?' + this.exportForm.getUrlParams()
      window.location.href = url
    },

    setExportData () {
      this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns())
    },

    removeFooters () {
      document.querySelectorAll('.vuetable > tfoot').forEach(footer => {
        footer.remove()
      })
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    updateImpactCostChartData (data) {
      this.impactCost.series = [
        {
          name: 'mail',
          color: '#EC77FF',
          data: data.mailing,
        },
      ]
    },

    getimpactCostStats () {
      if (!this.globalControl.initDate || !this.globalControl.endDate) {
        return
      }

      GeneralStatsService.getimpactCostStats({
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
        globals: this.globalControl.globals,
        daysOrHour: this.impactCost.daysOrHour,
      }).then((data) => {
        this.updateImpactCostChartData(data)
      })
    },

    selectDates (dates) {
      this.globalControl.initDate = dates[0]
      this.globalControl.endDate = dates[1]
      this.getDataFromApi()
    },

  },
  computed: {
    hasCampaigns () {
      return this.content.length !== 0
    },

    headers () {
      const headers = [
        {
          text: this.$t('Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Campaña'),
          value: 'campaign_name',
        },
        {
          text: this.$t('Tipo'),
          value: 'campaign_type_name',
        },
        {
          text: this.$t('Fecha'),
          value: 'campaign_date',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Entregados'),
          value: 'delivered_total',
        },
        {
          text: this.$t('Abiertos'),
          value: 'opened_unique',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_unique',
        },
        {
          text: 'Hard Bounces',
          value: 'hard_bounced_unique',
        },
        {
          text: 'Soft Bounces',
          value: 'soft_bounced_unique',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
      ]
      if (!this.$isSectionsBlockeds('costs')) {
        headers.push({
          text: this.$t('Coste'),
          align: 'start',
          value: 'cost',
          class: 'w-8',
        })
      }

      return headers
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    'impactCost.daysOrHour': function (newVal, oldVal) {
      this.getimpactCostStats()
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    locale () {
      this.setGraphicsData()
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
  created () {
    this.globalControl.globals = false
  },
}
